import { appStateService, store } from "App";
import { ChatInstance, IAIModelProcess } from "models/index";
import {
	ChatCompletionCreateParamsNonStreaming,
	ChatCompletionMessageParam
} from "openai/resources";
import { AIChatSelectors, UserSelectors } from "redux/selectors";
import {
	OpenAIModelVersion,
	OpenAIService
} from "services/openai/OpenAIService";
import { TrainingData } from "../training";
import { CompanySegment } from "services/companies/CompanySegment";
import { aiChatsReducer } from "redux/reducers";
import { Timestamp } from "firebase/firestore";
import { chatOwners } from "static/index";

/**
 * Class for managing and consuming Chat Services from OpenAI API.
 */
class CompanyOpenAIChatService extends CompanySegment<ChatInstance> {
	// _defaultMessages: ChatCompletionMessageParam[] = [
	// 	{
	// 		role: "system",
	// 		content: Promise.resolve(
	// 			TrainingData.assumptions.reduce(
	// 				async (prev, current) =>
	// 					await this.dataPipe(`${prev} ${current}`)
	// 			)
	// 		)
	// 	}
	// ];
	private _trainingString = "";

	constructor(companyId: string) {
		super(
			companyId,
			"aiChats",
			aiChatsReducer.setList,
			aiChatsReducer.setQueried
		);
		appStateService.logger.log("OpenAI Chat Service initiated.");
	}

	/**
	 *
	 * @param trainingString
	 * @returns
	 */
	async dataPipe(trainingString: string): Promise<string> {
		// let finalString = trainingString ?? "";
		this._trainingString = trainingString ?? "";

		if (!this._trainingString || this._trainingString === "") return "";
		// if (!finalString || finalString === "") return "";

		const processes = [
			{
				name: "replace-user-name",
				fn: async (input: string): Promise<string> => {
					const userDisplayName =
						appStateService.user.getDisplayName();

					return await Promise.resolve(
						input.replaceAll("${userName}", userDisplayName)
					);
				}
			},
			{
				name: "replace-company-name",
				fn: async (input: string): Promise<string> => {
					const companyName =
						await appStateService.user.getActiveCompanyName();

					return input.replaceAll("${companyName}", companyName);
				}
			}
		];

		for (let process in processes) {
			this._trainingString = await processes[process].fn(
				this._trainingString
			);
		}

		return this._trainingString;
	}

	/**
	 *
	 * @returns
	 */
	async getDefaultMessages(): Promise<ChatCompletionMessageParam[]> {
		let resolvedContent = TrainingData.assumptions.reduce(
			(prev, current) => {
				return prev === "" ? current : `${prev} ${current}`;
			},
			""
		);

		resolvedContent = await this.dataPipe(resolvedContent);

		return [
			{
				role: "system",
				content: resolvedContent
			}
		];
	}

	// /**
	//  * Sends a message to the OpenAI API.
	//  * Also, stores the interaction in the database.
	//  *
	//  * @param message The message to be sent as a prompt.
	//  * @param modelVersion [Optional] The model version to be used.
	//  * @returns A promise with the response.
	//  */
	// async sendMessages(
	// 	messages: string[],
	// 	modelVersion:
	// 		| undefined
	// 		| OpenAIModelVersion = OpenAIModelVersion.GPT3_1106
	// ): Promise<string> {
	// 	const userId =
	// 		UserSelectors.selectUserProfileId(store.getState()) ?? "anonymous";

	// 	const gptResponse = await OpenAIService.chat.completions.create({
	// 		user: userId,
	// 		messages: [
	// 			{
	// 				role: "system",
	// 				content:
	// 					"Você é um(a) ótimo(a) assistente e está " +
	// 					"recebendo algumas mensagens para responder"
	// 			},
	// 			...messages.map((msg) => ({ role: "user", content: msg }))
	// 		],
	// 		model: modelVersion
	// 	});

	// 	const response =
	// 		gptResponse.choices.length > 0
	// 			? gptResponse.choices[0].message.content
	// 			: "Error: No response.";

	// 	return response;
	// }

	/**
	 * Sends a message to the OpenAI API.
	 * Also, stores the interaction in the database.
	 *
	 * @param message The message to be sent as a prompt.
	 * @param parentChatId The Chat ID which will contain the submitted prompt.
	 * @param modelId The model id to be used.
	 * @param openAIModelVersion [Optional] The model version to be used.
	 * @returns A promise with the response.
	 */
	async sendMessage(
		message: string,
		parentChatId: string,
		modelId: string = "bee-ai-chat",
		openAIModelVersion:
			| undefined
			| OpenAIModelVersion = OpenAIModelVersion.GPT3_1106
	): Promise<ChatInstance> {
		const modelProcessService =
			appStateService.company.aiModelProcess.get();
		const userId =
			UserSelectors.selectUserProfileId(store.getState()) ?? "anonymous";
		const input = message.trim();

		// TODO: Implement the call to find parent chat ID to store the messages, or create a new chat.
		let parentChat =
			!parentChatId || parentChatId === ""
				? null
				: await this.getItemById(parentChatId);

		if (!parentChat) {
			// Create new parentChat to contain this query
			parentChat = {
				id: "",
				deleted: false,
				timestamp: Timestamp.now(),
				messages: {
					sent: [],
					received: []
				},
				ownerId: userId,
				title: ""
			};
		}

		const _defaultMessages = await this.getDefaultMessages();

		// Unifying the messages to be sent to the OpenAI API as historical data
		const historicalMessages = [
			...(_defaultMessages.length > 0 ? _defaultMessages : [])
		];

		parentChat.messages.sent.forEach((msg, index) => {
			historicalMessages.push({
				role: "user",
				content: msg.content
			});

			if (parentChat.messages.received[index]) {
				historicalMessages.push({
					role: "system",
					content: parentChat.messages.received[index]?.content
				});
			}
		});

		// Creating chat completion data
		const completionData: ChatCompletionCreateParamsNonStreaming = {
			user: userId,
			messages: [
				...historicalMessages,
				{ role: "user", name: "User", content: input }
			],
			model: openAIModelVersion
		};
		let modelProcessData: IAIModelProcess;

		try {
			const newMessageTime = Timestamp.now();
			const newMessageId = newMessageTime.toMillis().toString();

			parentChat.messages.sent.push({
				id: `${newMessageId}`,
				deleted: false,
				timestamp: newMessageTime,
				content: input,
				ownerId: userId
			});

			store.dispatch(aiChatsReducer.setActiveChat(parentChat));

			if (!parentChat.id || parentChat?.id === "") {
				// TODO: Define the title of the chat being created
				// gptResponse.choices[0].message;

				await this.createItem(parentChat, async (createdItem) => {
					parentChat = createdItem;
					return;
				});
			} else {
				await this.updateItem(parentChat, async (updatedItem) => {
					parentChat = updatedItem;
					return;
				});
			}

			modelProcessData = await modelProcessService.logModelProcess(
				input,
				userId,
				modelId,
				openAIModelVersion
			);

			// Advances to processing state immediately
			await modelProcessService.logModelProcessProcessing(
				modelProcessData
			);

			const gptResponse = await OpenAIService.chat.completions.create({
				...completionData
			});

			const response =
				gptResponse.choices.length > 0
					? gptResponse.choices[0].message.content
					: "Error: No response.";

			await this.onCompletion(parentChat.id, response);

			parentChat = await this.getItemById(parentChat.id);

			// Sets an active chat
			store.dispatch(aiChatsReducer.setActiveChat(parentChat));

			// Advances to processed state immediately
			await modelProcessService.logModelProcessCompletion(
				modelProcessData,
				response,
				gptResponse.usage
			);

			return parentChat;
		} catch (error) {
			// Sets an error state to the entry processing
			await modelProcessService.logModelProcessError(
				modelProcessData,
				error
			);

			appStateService.logger.error(error);
			throw error;
		}
	}

	/**
	 * Executes a message completion workload,
	 * which is synchronizing the DB.
	 *
	 * @param promptId The ID of the container Prompt
	 * @param completion The String of the completion by the chat tool
	 * @returns The updated Prompt instance
	 */
	async onCompletion(promptId: string, completion: string) {
		const responseTime = Timestamp.now();
		const responseId = responseTime.toMillis().toString();

		let promptInstance = await this.getItemById(promptId);

		promptInstance.messages.received.push({
			id: `${responseId}`,
			deleted: false,
			timestamp: responseTime,
			content: completion,
			ownerId: chatOwners.system
		});

		await this.updateItem(promptInstance, async (updatedItem) => {
			promptInstance = updatedItem;
			return;
		});

		return promptInstance;

		// setCompletedMessages([...completedMessages, completion]);
		// setHasError(false);
	}

	/**
	 * Sends an Image as a message to the OpenAI API,
	 * for image understanding.
	 *
	 * @param query The query to be sent as a prompt.
	 * @param image The image to be sent as a message input.
	 * @param modelId The model id to be used.
	 * @param openAIModelVersion [Optional] The model version to be used.
	 * @returns A promise with the response.
	 */
	async sendImage(
		query: string,
		image: File | Blob,
		modelId: string,
		openAIModelVersion: OpenAIModelVersion = OpenAIModelVersion.GPT4_VISION
	): Promise<string> {
		const modelProcessService =
			appStateService.company.aiModelProcess.get();
		const userId =
			UserSelectors.selectUserProfileId(store.getState()) ?? "anonymous";
		const imageBase64 = atob(await image.text());
		let modelProcessData: IAIModelProcess;

		try {
			modelProcessData = await modelProcessService.logModelProcess(
				query,
				userId,
				modelId,
				openAIModelVersion
			);

			// Advances to processing state immediately
			await modelProcessService.logModelProcessProcessing(
				modelProcessData
			);

			const gptResponse = await OpenAIService.chat.completions.create({
				user: userId,
				model: openAIModelVersion,
				messages: [
					{
						role: "user",
						name: "User",
						content: [
							{ type: "text", text: query },
							{
								type: "image_url",
								image_url: {
									url: `data:${
										typeof image === "string"
											? "base64"
											: image.type
									};base64,${imageBase64}`
								}
							}
						]
					}
				]
			});

			const response =
				gptResponse.choices.length > 0
					? gptResponse.choices[0].message.content
					: "Error: No response.";

			// Advances to processed state immediately
			await modelProcessService.logModelProcessCompletion(
				modelProcessData,
				response,
				gptResponse.usage
			);

			return response;
		} catch (error) {
			// Logging the error to the database
			await modelProcessService.logModelProcessError(
				modelProcessData,
				error
			);

			appStateService.logger.error(error);
			throw error;
		}
	}

	/**
	 * Preloads a chat instance in the state layer,
	 * based on a given chat ID.
	 *
	 * @param chatId The Chat ID to query
	 */
	async preloadChat(chatId: string): Promise<void> {
		if (!chatId || chatId === "") return;

		const foundData = await this.getItemById(chatId);

		if (!foundData) {
			// TODO: Redirect to the 404 not-located
			return;
		}

		await this.setActiveChat(foundData);
	}

	/**
	 * Sets a given ChatInstance as the active one in the state layer.
	 *
	 * @param chat The ChatInstance to be set as active. If null, it will clear the state portion.
	 */
	async setActiveChat(chat: null | ChatInstance): Promise<void> {
		store.dispatch(aiChatsReducer.setActiveChat(chat));
	}

	/**
	 * Retrieves the active ChatInstance of the state layer
	 *
	 * @returns
	 */
	getActiveChat() {
		return AIChatSelectors.getAIActiveChat(store.getState());
	}

	/**
	 * Resets the active chat in the state layer
	 */
	resetActiveChat() {
		store.dispatch(aiChatsReducer.resetActiveChat());
	}
}

export { CompanyOpenAIChatService };
