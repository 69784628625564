import { appStateService } from "App";
import { useMessageModal } from "../message/useMessageModal";
import { MessageModalType } from "enums";

export interface ConfirmModalOptions {
	// title?: string;
	onCancel?: () => void | Promise<void>;
	text: string;
	setText?: (text: string) => void;
	onOpen: (
		newText?: undefined | string,
		confirmCallBack?: undefined | (() => void)
	) => void;
}

/**
 *
 *
 * @returns
 */
export const useConfirmModal = (): ConfirmModalOptions => {
	const { text, setText, setOpen, setTitle, setType, cancel } =
		useMessageModal();

	const onOpen = async (
		newText?: undefined | string,
		confirmCallBack?: undefined | (() => void)
	) => {
		setTitle("Confirmar Ação");
		setType(MessageModalType.info);
		if (newText && newText.length > 0) setText(newText);
		else setText("Executar a ação selecionada?");

		// Prepares the callback to be executed right after confirmation is activated
		if (typeof confirmCallBack === "function") {
			appStateService.appManager.setConfirmCallback(confirmCallBack);
		}

		setOpen(true);
	};

	// useEffect(() => {
	// 	setTitle("Confirmar Ação");
	// 	setType(MessageModalType.info);
	// }, []);

	return { onCancel: cancel, text, setText, onOpen };
};
